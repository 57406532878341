/* eslint react/prop-types: 0 */
import React from 'react';
import Layout from 'components/layout';
import { Container, Date, Title } from './exampleTemplate.css';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Button } from 'components/modal/modal.css.js';
import 'katex/dist/katex.min.css';

const ExampleTemplate = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <Container>
        <Title>{frontmatter.title}</Title>
        <Date>{frontmatter.date}</Date>
        <div style={{ padding: '2rem', maxWidth: '700px', margin: 'auto' }}>
          <Img
            fluid={
              frontmatter.featuredImage
                ? frontmatter.featuredImage.childImageSharp.fluid
                : {}
            }
          />
        </div>
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <Button style={{ margin: '1rem 0 1rem 0' }} href="/examples">
          {'< Back to Examples'}
        </Button>
      </Container>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default ExampleTemplate;
