import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';

export const Button = styled.a`
display: inline-block
  -webkit-appearance: none;
  background-color: #2d4ef5;
  border-radius: 5px;
  border: none;
  color: white;
  border: 1px solid #ddd;
  cursor: pointer;
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 4rem 2rem 2rem;
  padding: 1rem 2rem;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.2s all ease;

  &:active,
  &:focus {
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
    outline: none;
  }

  &:hover {
    background-color: #2640c3;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
    transform: translateY(-1px);
  }

  & + & {
    margin-left: 1rem;
  }

  ${MEDIA.TABLET`
    margin: 2rem 1rem 1rem;
    font-size: 1.2rem;
  `}

  ${MEDIA.PHONE`
    font-size: 1.2rem;
    margin: 2rem 0 0 0;
  `}
`;

export const Close = styled.button`
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-size: 4rem;
  font-weight: normal;
  padding: 1rem;
  line-height: 4rem;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
`;
